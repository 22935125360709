






















import Vue from 'vue';
import BusinessList from '@/components/account/BusinessList.vue';

export default Vue.extend({
  components: {
    BusinessList,
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
