

























import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import MediaBusiness from '@/components/media/Business.vue';

interface VuexBindings {
  businesses: ApiResource.Business[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    MediaBusiness,
  },
  data() {
    return {
      error: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters('business', {
      businesses: 'List',
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.isLoading = true;

      await this.$store.dispatch('account/ListBusinesses')
        .catch((e) => { this.error = e; });

      this.isLoading = false;
    },
  },
});
