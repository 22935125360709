

























































import Vue from 'vue';
import { ApiResource } from '@/types';

export default Vue.extend({
  props: {
    business: {
      required: true,
      type: Object as () => ApiResource.Business,
      validator(obj: ApiResource.Business) {
        return obj.object === 'business';
      },
    },
  },
});
